import { iPlugin } from '../components/sessionControls/plugins/iPlugin'
import axiosClient from '../utils/axiosClient'

class PluginService {
  private static instance: PluginService

  private constructor() {}

  public static getInstance(): PluginService {
    if (!PluginService.instance) {
      PluginService.instance = new PluginService()
    }
    return PluginService.instance
  }

  /**
   * Get all plugins
   * @param baseSettingsId base id of the assistant, optional
   */
  public async getPlugins(baseSettingsId: string = ''): Promise<iPlugin[]> {
    if (!baseSettingsId || baseSettingsId === '') baseSettingsId = 'null'
    return (await axiosClient.get(`/plugins?baseSettingsId=` + baseSettingsId))
      .data as iPlugin[]
  }

  /**
   * @deprecated commented out in backend as well
   */
  // public async getPlugin(pluginId: string): Promise<iPlugin> {
  //   return (await axiosClient.get(`/plugins/${pluginId}`)).data as iPlugin
  // }

  public async createPlugin(plugin: any): Promise<iPlugin> {
    return (await axiosClient.post(`/plugins`, plugin)).data as iPlugin
  }

  public async updatePlugin(plugin: any): Promise<void> {
    await axiosClient.put(`/plugins`, plugin)
  }

  public async deletePlugin(pluginId: string): Promise<void> {
    await axiosClient.delete(`/plugins/${pluginId}`)
  }
}

export default PluginService.getInstance()
